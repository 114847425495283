<template>
  <Tabela />
</template>

<script>
import Tabela from "@/components/gestao/Tabela.vue";

export default {
  components: {
    Tabela
  }
};
</script>
